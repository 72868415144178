<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/inner-banner.png" alt="First slide">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">Blog</h2>
          <p class="breadcrumb-item"><a href="#">Home</a> / Blog</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="blog-post-cont"> <img src="assets/images/post-1.jpeg" class="img-fluid" alt="item 1">
              <div class="text-cont">
                <h6><a href="#">Nilanjan</a> | <a href="#">January 19, 2017</a></h6>
                <h5><a href="#">What is Lorem Ipsum?</a></h5>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                <a href="#" class="readdmore">Read More <i class="icofont icofont-long-arrow-right"></i></a> </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="blog-post-cont"> <img src="assets/images/post-2.jpeg" class="img-fluid" alt="item 1">
              <div class="text-cont">
                <h6><a href="#">Nilanjan</a> | <a href="#">January 19, 2017</a></h6>
                <h5><a href="#">Where does it comes from?</a></h5>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                <a href="#" class="readdmore">Read More <i class="icofont icofont-long-arrow-right"></i></a> </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="blog-post-cont"> <img src="assets/images/post-3.jpeg" class="img-fluid" alt="item 1">
              <div class="text-cont">
                <h6><a href="#">Nilanjan</a> | <a href="#">January 19, 2017</a></h6>
                <h5><a href="#">What should we do now?</a></h5>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                <a href="#" class="readdmore">Read More <i class="icofont icofont-long-arrow-right"></i></a> </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="blog-post-cont"> <img src="assets/images/post-4.jpeg" class="img-fluid" alt="item 1">
              <div class="text-cont">
                <h6><a href="#">Nilanjan</a> | <a href="#">January 19, 2017</a></h6>
                <h5><a href="#">What is Lorem Ipsum?</a></h5>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                <a href="#" class="readdmore">Read More <i class="icofont icofont-long-arrow-right"></i></a> </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      	<div class="sidebar">
        	<div class="sidebar-item">
            	<h5>Category</h5>
                <hr/>
                <ul>
                	<li><a href="#">Business</a></li>
                    <li><a href="#">Corporate</a></li>
                    <li><a href="#">Multipurpose</a></li>
                    <li><a href="#">Creative</a></li>
                    <li><a href="#">Technology</a></li>
                </ul>
            </div>
            <div class="sidebar-item">
            	<h5>Archives</h5>
                <hr/>
                <ul>
                	<li><a href="#">2017</a></li>
                    <li><a href="#">2016</a></li>
                    <li><a href="#">2015</a></li>
                    <li><a href="#">2014</a></li>
                    <li><a href="#">2013</a></li>
                </ul>
            </div>
            <div class="sidebar-item">
            	<h5>Recent Posts</h5>
                <hr/>
                <ul>
                	<li><a href="#">Iaculisnunc sedaugue lacus</a></li>
                    <li><a href="#">Portanon pulvinar nequelaoree</a></li>
                    <li><a href="#">Quis variusquam quisqueid</a></li>
                    <li><a href="#">Eget miproin sedliberoenimsed</a></li>
                    <li><a href="#">Portanon pulvinar nequelaoree</a></li>
                </ul>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>