<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/inner-banner.png" alt="First slide">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">Blog Details</h2>
          <p class="breadcrumb-item"><a href="#">Home</a> / Blog Details</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="blog-post-cont"> <img src="assets/images/post-1.jpeg" class="img-fluid" alt="item 1">
              <div class="text-cont">
                <h6><a href="#">Nilanjan</a> | <a href="#">January 19, 2017</a></h6>
                <h5><a href="#">What is Lorem Ipsum?</a></h5>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words.</p>
                 
                 <div class="social-cont">
                 	<div class="alignleft"><h5>Share us on:</h5></div>
                 	<div class="blog-details-social alignleft">
                    	<ul class="social">
                            <li><a href="#"><i class="icofont icofont-social-facebook"></i></a></li>
                            <li><a href="#"><i class="icofont icofont-social-twitter"></i></a></li>
                            <li><a href="#"><i class="icofont icofont-social-linkedin"></i></a></li>
                            <li><a href="#"><i class="icofont icofont-social-pinterest"></i></a></li>
                            <li><a href="#"><i class="icofont icofont-social-dribble"></i></a></li>
                       </ul>
                    </div>
                 </div>   
                 
                <h5>Comments</h5>
               
            <form action="process.php" method="post" name="ContactForm" id="ContactForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" name="fname" placeholder="First Name *">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" name="lname" placeholder="Last Name *">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="email" class="form-control" name="email" placeholder="Email *">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="text" class="form-control" name="subject" placeholder="Subject *">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <textarea rows="5" class="form-control" name="comment" placeholder="Your Message *"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
         
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      	<div class="sidebar">
        	<div class="sidebar-item">
            	<h5>Category</h5>
                <hr/>
                <ul>
                	<li><a href="#">Business</a></li>
                    <li><a href="#">Corporate</a></li>
                    <li><a href="#">Multipurpose</a></li>
                    <li><a href="#">Creative</a></li>
                    <li><a href="#">Technology</a></li>
                </ul>
            </div>
            <div class="sidebar-item">
            	<h5>Archives</h5>
                <hr/>
                <ul>
                	<li><a href="#">2017</a></li>
                    <li><a href="#">2016</a></li>
                    <li><a href="#">2015</a></li>
                    <li><a href="#">2014</a></li>
                    <li><a href="#">2013</a></li>
                </ul>
            </div>
            <div class="sidebar-item">
            	<h5>Recent Posts</h5>
                <hr/>
                <ul>
                	<li><a href="#">Iaculisnunc sedaugue lacus</a></li>
                    <li><a href="#">Portanon pulvinar nequelaoree</a></li>
                    <li><a href="#">Quis variusquam quisqueid</a></li>
                    <li><a href="#">Eget miproin sedliberoenimsed</a></li>
                    <li><a href="#">Portanon pulvinar nequelaoree</a></li>
                </ul>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>