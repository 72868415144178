<footer class="footer">
  <div class="container">
    <!-- <div class="row">
      <div class="col-lg-4">
        <div class="footer-item">
          <img class="img-fluid" src="assets/images/gg_logo7.png">
          <p>Here at Goals and Gambles we strive to create a relationships built on clarity unmatched in web
            development.
            <br />Reach out to us anytime
          </p>

        </div>
        <div class="footer-item">
          <ul class="social">
            <li><a href="https://www.facebook.com/gandgllc/"><i class="icofont icofont-social-facebook"></i></a></li>
            <li><a href="#"><i class="icofont icofont-social-twitter"></i></a></li>
            <li><a href="https://www.linkedin.com/company/goals-and-gambles/"><i
                  class="icofont icofont-social-linkedin"></i></a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 offset-lg-1">
        <div class="footer-item">
          <h4>Quick Links</h4>
          <ul>
            <li><a class="nav-link" routerLink="/home1">Home</a></li>
            <li><a class="nav-link" routerLink="/services">Services</a></li>
            <li><a class="nav-link" routerLink="/portfolio">Portfolio</a></li>
            <li><a class routerLink="/contact">Quote</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="footer-item">
          <h4>Categories</h4>
          <ul>
            <li><a class="nav-link" routerLink="/services">Web Design</a></li>
            <li><a class="nav-link" routerLink="/services">Web Development</a></li>
            <li><a class="nav-link" routerLink="/services">Online Marketing</a></li>
            <li><a class="nav-link" routerLink="/services">Application Design/Development</a></li>
            <li><a class="nav-link" routerLink="/services">Online Training</a></li>
          </ul>
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="footer-bottom">
          <p>All Contents © 2021 Goals and Gambles LLC. All Rights Reserved</p>
          <p>If you are using a screen reader and having difficulty with this website, please call <a
              href="tel:+18177131245">817-713-1245</a>.</p>
        </div>
      </div>
    </div>
  </div>
</footer>
<!--div id="qnimate" class="off">
  <div id="search" class="open">
    <button data-widget="remove" id="removeClass" class="close" type="button">×</button>
    <form action="#" method="post" autocomplete="off">
      <input type="text" placeholder="Type search keywords here" value="" name="term" id="term">
      <button class="btn btn-primary btn-xl" type="submit"><span class="glyphicon glyphicon-search"></span> Search</button>
    </form>
  </div>
</div-->
