<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/inner-banner.png"
        alt="First slide">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">Portfolio</h2>
          <p class="breadcrumb-item"><a href="#">Home</a> / Portfolio</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <!-- <div class="portfolioFilter text-center"> <a href="#" data-filter="*" class="current">All Categories</a> / <a href="#" data-filter=".people">Applications</a> / <a href="#" data-filter=".places">React.js</a> / <a href="#" data-filter=".food">Unity</a> / <a href="#" data-filter=".objects">Responsive</a> / </div> -->
        <div class="portfolioContainer">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 objects">
              <div class="grid objects">
                <figure class="effect-lily"> <img src="assets/images/portfolio/pb.png" class="img-fluid" alt="img01" />
                  <figcaption>
                    <h2>Panel Builder</h2>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/pb.png"><b>View Large</b></a></p>
                  </figcaption>
                </figure>
                <p>Panel Builder is a progressive webapp, built to provide turnkey product builds for internal customers
                  in water industry</p>
                <p><a
                    href="https://www.youtube.com/watch?v=O7Tq1AvaKDI&list=LL71eyA1JEz-l0Rf9h7Gi8XA&index=10&t=0s">Demo</a>
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 objects">
              <div class="grid objects">
                <figure class="effect-lily"> <img src="assets/images/portfolio/pb.png" class="img-fluid" alt="img01" />
                  <figcaption>
                    <h2>Panel Builder</h2>

                    <p><a class="info magnific-popup" href="assets/images/portfolio/pb.png"><b>View Large</b></a></p>
                  </figcaption>
                </figure>
                <p>Panel Builder is a progressive webapp, built to provide turnkey product builds for internal customers
                  in water industry</p>
                <p><a
                    href="https://www.youtube.com/watch?v=O7Tq1AvaKDI&list=LL71eyA1JEz-l0Rf9h7Gi8XA&index=10&t=0s">Demo</a>
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 people places">
              <div class="grid people places">
                <figure class="effect-lily"> <img src="assets/images/portfolio/summit.png" class="img-fluid"
                    alt="img01" />
                  <figcaption>
                    <h2>Summit Movers</h2>

                    <p><a class="info magnific-popup" href="assets/images/portfolio/summit.png"><b>View Large</b></a>
                    </p>
                  </figcaption>
                </figure>
                <p>Summit Movers is a family owned logistics company based in DFW</p>
                <p><a href="https://www.summitmoversdfw.com/">URL</a></p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 food">
              <div class="grid food">
                <figure class="effect-lily"> <img src="assets/images/portfolio/zom.png" class="img-fluid" alt="img01" />
                  <figcaption>
                    <h2>I'm a Zombie</h2>

                    <p><a class="info magnific-popup" href="assets/images/portfolio/zom.png"><b>View Large</b></a></p>
                  </figcaption>
                </figure>
                <p>This was a fun project using Unity platform</p>
                <p><a href="https://ymtony817.itch.io/im-a-zombie">Demo</a></p>
              </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 people places">
              <div class="grid people places">
                <figure class="effect-lily"> <img src="assets/images/portfolio/4.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 4</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/4.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 places objects">
              <div class="grid places objects">
                <figure class="effect-lily"> <img src="assets/images/portfolio/5.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 5</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/5.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 people food objects">
              <div class="grid people food objects">
                <figure class="effect-lily"> <img src="assets/images/portfolio/6.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 6</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/6.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
