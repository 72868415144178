<nav class="navbar navbar-expand-md btco-hover-menu navbar-1">
  <div class="container"> <a class="navbar-brand" routerLink="/"><img src="assets/images/logo.png" class="img-fluid"
        alt="logo" style="max-width: 150px;"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"> <span
        class="navbar-toggler-icon"></span> </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <!--li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Home </a>
          <ul class="dropdown-menu">
            <li><a class="" routerLink="/home1">Home 1</a></li>
            <li><a class="dropdown-item" routerLink="/home2">Home 2</a></li>
          </ul>
        </li-->
        <!-- <li class="nav-item"> <a class="nav-link" routerLink="/home1">Home</a> </li> -->
        <!--li class="nav-item"> <a class="nav-link" routerLink="/about">About</a> </li-->
        <!-- <li class="nav-item"> <a class="nav-link" routerLink="/services">Services</a> </li> -->
        <!-- <li class="nav-item"> <a class="nav-link" routerLink="/portfolio">Portfolio</a> </li> -->
        <!--li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Blog </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" routerLink="/bloglist">Quote</a></li>
            <li><a class="dropdown-item" routerLink="/blogdetails">Blog Details</a></li>
          </ul>
        </li-->
        <!-- <li class="nav-item"> <a class="nav-link" routerLink="/contact">Schedule Meeting</a> </li> -->
      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item"> <a class="nav-link btn btn-primary" href="https://calendly.com/goalsandgambles/30min"
            target="_blank">Schedule Meeting</a></li>
        <li class="nav-item"> <a class="nav-link btn btn-primary" routerLink="/login" target="_blank">Client Login</a></li>

        <!--li class="nav-item"> <a class="nav-link" id="addClass" href="#"> <i class="icofont icofont-search-alt-1"></i> </a></li-->
      </ul>
    </div>
  </div>
</nav>