<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/inner-banner.png" alt="First slide">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">Services</h2>
          <p class="breadcrumb-item"><a href="#">Home</a> / Services</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-paint"></i></div>
          <div class="text-cont">
            <h6>Web Design</h6>
            <p>The planning stage of any idea and wireframing is the most important part of creating something great</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-code-alt"></i></div>
          <div class="text-cont">
            <h6>Web Development</h6>
            <p>Frontend, backend, apis etc we do it</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-dashboard-web"></i></div>
          <div class="text-cont">
            <h6>Online Marketing</h6>
            <p>A very important part of any online business is finding that business online</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-brand-android-robot"></i></div>
          <div class="text-cont">
            <h6>Android Development</h6>
            <p>Getting your product on Google Play store</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-iphone"></i></div>
          <div class="text-cont">
            <h6>iPhone Development</h6>
            <p>Getting your product on Apple App store with the latest Updates</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-video-clapper"></i></div>
          <div class="text-cont">
            <h6>Online Training</h6>
            <p>Need a mentor, code reviews or training for your developers we can do that.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--section class="subscribe">
  <div class="container">
    <div class="row justify-content-center">
    	<div class="col-lg-8">
        	<div class="subscribe-cont">
                <h2>Subscribe our Newsletter</h2>
                <p>established fact that a reader will be distracted by the readable content Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                <div class="subscribe-form-cont">
                <form action="#" method="post">
                	<input type="text" class="subscribe-txt" placeholder="Subscribe with our email">
                    <input type="submit" class="subscribebtn" value="Submit">
                </form>
                </div>
            </div>
        </div>
    </div>
  </div>
</section--> 
<section class="section-padding-ash">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="section-title text-center">
          <h2>Our Affordable Price list</h2>
          <h6>**These are starting estimates for websites and applications**</h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <div class="pricing-cont">
          <h5>Basic Website( React, Angular or CMS) </h5>
          <div class="price-amt"><h3>$400*<small></small></h3></div>
          <div class="price-items">
          	<p>5 page with Contact Form</p>
            <p>Domain & Hosting</p>
            <p>Mobile friendly</p>
            <p>Graphics</p>
            <p>Paypal payment button</p>
            <p>2 Year Support</p>
            <p>Free SSL</p>
            <p>Free Updates</p>
          </div>
          <div class="text-center price-btn-cont"><a class="btn btn-primary" routerLink="/contact">Contact Us </a></div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <div class="pricing-cont">
          <h5>Premium Web application</h5>
          <div class="price-amt"><h3>$800* <small></small></h3></div>
          <div class="price-items">
          	<p>5/10 page with Contact Form</p>
            <p>Domain & Hosting</p>
            <p>Graphics</p>
            <p>Paypal/stripe payment button</p>
            <p>Mobile friendly</p>
            <p>2 Year Support</p>
            <p>Free SSL</p>
            <p>Free Updates*</p>
          </div>
          <div class="text-center price-btn-cont"><a class="btn btn-primary" routerLink="/contact">Contact Us </a></div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <div class="pricing-cont">
          <h5>Application Development</h5>
          <div class="price-amt"><h3>$30000-$65000*<small></small></h3></div>
          <div class="price-items">
          	<p>Payment plan options</p>
            <p>Detailing planning stage you can use with any company</p>
            <p>3 Years Support</p>
          </div>
          <div class="text-center price-btn-cont"><a class="btn btn-primary" routerLink="/contact"> Contact Us </a></div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <div class="pricing-cont">
          <h5>Remote Corp to Corp contracts</h5>
          <div class="price-amt"><h3>$25-70/ <small>Per hour</small></h3></div>
          <div class="price-items">
            <p>3-4 team members available</p>
            <p>All Javascript frameworks</p>
            <p>PHP/Laravel</p>
            <p>AWS and Azure</p>
            

            
          </div>
          <div class="text-center price-btn-cont"><a class="btn btn-primary" routerLink="/contact"> Contact Us  </a></div>
        </div>
      </div>
    </div>
  </div>
</section>   