<div id="myCarousel" class="carousel slide banner-1" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
    <li data-target="#myCarousel" data-slide-to="1"></li>
    <li data-target="#myCarousel" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="first-slide" src="assets/images/banner-1.jpg" alt="First slide" />
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">
            Building applications with latest technology
          </h2>
          <h6>
            Here at Goals and Gambles we strive to create a relationship built
            on providing as much clarity as possible,
          </h6>
          <p class="hidden-sm-down">
            <a class="btn btn-md btn-default" href="#schedule-meeting" role="button">Reach out to us today</a>
          </p>
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <img class="second-slide" src="assets/images/banner-2.jpg" alt="Second slide" />
      <div class="container">
        <div class="carousel-caption banner1">
          <h2 class="wow fadeInUp">We leave room for growth</h2>
          <h6>
            Asking the right question doesn't come easy in business, we strive
            to be the ones you get the correct answer from,
          </h6>
          <p class="hidden-sm-down">
            <a class="btn btn-md btn-default" href="#schedule-meeting" role="button">Learn more</a>
          </p>
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <img class="third-slide" src="assets/images/banner-3.png" alt="Third slide" />
      <div class="container">
        <div class="carousel-caption banner1 text-right">
          <h2 class="wow fadeInUp">
            We are <span>Goals and </span>Gambles<span>.</span>
          </h2>
          <h6>
            Web Development Services, Technical Training, Application
            Development, Graphic design and SEO,
          </h6>

        </div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
<br />
<section>
  <div class="row justify-content-center">
    <div class="col-lg-10">
      <div class="section-title text-center">
        <h2>Introducing Our Newest Product!</h2>
        <h6>click below for demo</h6>
      </div>
    </div>
  </div>
  <div class="pricing-table">
    <div class="ptable-item">

      <div class="ptable-ti-tle">
        <div class="productTitle">
          <img src="assets/images/product3.png" alt="" width="150" height="100">

        </div>
        <p>TruHCL is here to give homeowners more tools in buying a home or land!</p>
        <button>Subscribe</button>
      </div>
      <hr class="horLine" />
      <div class="ptable-body">
        <div class="ptable-description">
          <ul>
            <li><input type="checkbox" id="check04" checked /><label for="check04"> Updated Public data feed</label>
            </li>
            <li><input type="checkbox" id="check05" checked /><label for="check05">Updated data</label></li>
            <li><input type="checkbox" id="check06" checked /><label for="check06">Well-commented Code / single line</label></li>
            <li><input type="checkbox" id="check07" checked /><label for="check07">Api integration</label></li>
            <li><input type="checkbox" id="check08" checked /><label for="check08">Real time metrics for your form</label></li>
            <li><input type="checkbox" id="check09" checked /><label for="check09">Assigned development support</label></li>
          </ul>
        </div>

      </div>
      <div class="ptable-footer">
        <div class="ptable-action">
          <a href="https://calendly.com/goalsandgambles/30min">WATCH DEMO</a>
        </div>
      </div>
    </div>
    <!-- </div> -->

    <div class="ptable-item featured-item">

      <div class="ptable-ti-tle">
        <div class="productTitle">
          <img src="assets/images/product2.png" alt="" width="150" height="100">
          <!-- <div class="prod-title">
            <h1>car</h1>
            <h1>talk</h1>
          </div> -->
        </div>
        <p>GuardMail to help senior citizens against the common hacker and scammer</p>
        <button>Subscribe</button>
      </div>
      <hr class="horLine" />
      <div class="ptable-body">
        <div class="ptable-description">
          <ul>
            <li><input type="checkbox" id="check04" checked /><label for="check04">A.I generated assistance</label>
            </li>
            <li><input type="checkbox" id="check05" checked /><label for="check05">Responsive Design templates</label></li>
            <li><input type="checkbox" id="check06" checked /><label for="check06">Well-commented Code / single line</label></li>
            <li><input type="checkbox" id="check07" checked /><label for="check07">Api integration</label></li>
            <li><input type="checkbox" id="check08" checked /><label for="check08">Real time metrics for your form</label></li>
            <li><input type="checkbox" id="check09" checked /><label for="check09">Assigned development support</label></li>
          </ul>
        </div>
      </div>
      <div class="ptable-footer">
        <!-- <div class="ptable-action">
            <a href="https://calendly.com/goalsandgambles/30min">WATCH DEMO</a>
          </div> -->
        <div class="ptable-action">
          <a href="https://calendly.com/goalsandgambles/30min">WATCH DEMO</a>
        </div>
      </div>
      <!-- </div> -->
    </div>

    <div class="ptable-item">
      <!-- <div class="ptable-single"> -->
      <!-- <div class="ptable-header">
          <div class="ptable-title">
            <h2>KELLY BLUEBOOK</h2>
          </div>
          <div class="ptable-price">
            <h2><small>$</small>299<span>/ M</span></h2>
          </div>
        </div> -->
      <div class="ptable-ti-tle">
        <div class="productTitle">
          <img src="assets/images/product4.png" alt="" width="150" height="100">
          <!-- <div class="prod-title">
            <h1>kelly</h1>
            <h1>bluebook</h1>
          </div> -->
        </div>
        <p>Car Talk is a platform everyone can interact with! </p>
        <button>Subscribe</button>
      </div>
      <hr class="horLine" />
      <div class="ptable-body">
        <div class="ptable-description">
        <ul>
          <li><input type="checkbox" id="check04" checked /><label for="check04">Integration with Apple car play and Android Auto </label>
          </li>
          <li><input type="checkbox" id="check05" checked /><label for="check05">Responsive Design templates</label></li>
          <li><input type="checkbox" id="check06" checked /><label for="check06">Well-commented Code / single line</label></li>
          <li><input type="checkbox" id="check07" checked /><label for="check07">Api integration</label></li>
          <li><input type="checkbox" id="check08" checked /><label for="check08">Real time metrics for your form</label></li>
          <li><input type="checkbox" id="check09" checked /><label for="check09">Assigned development support</label></li>
        </ul>
        </div>
      </div>
      <div class="ptable-footer">
        <!-- <div class="ptable-action">
            <a href="https://calendly.com/goalsandgambles/30min">WATCH DEMO</a>
          </div> -->
        <div class="ptable-action">
          <a href="https://calendly.com/goalsandgambles/30min">WATCH DEMO</a>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</section>
<section class="section-padding-ash">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="section-title text-center">
          <h2>What we provide to our clients</h2>
          <h6>
            #1 goal is to provide all your business needs in web development
          </h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-paint"></i></div>
          <div class="text-cont">
            <h6>UI/UX Design</h6>
            <p>
              We design applications with top notch industry tools. Adobe XD,
              Figma, you name it.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-code-alt"></i></div>
          <div class="text-cont">
            <h6>Web Development</h6>
            <p>
              Experienced in React, Angular, Node.js, PHP, C#, Python to name a
              few.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-dashboard-web"></i></div>
          <div class="text-cont">
            <h6>Online Marketing</h6>
            <p>Google ads, CMS help, SEO </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont">
            <i class="icofont icofont-brand-android-robot"></i>
          </div>
          <div class="text-cont">
            <h6>Android Development</h6>
            <p>
              Getting your product on Google Play store.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont">
            <i class="icofont icofont-iphone"></i>
          </div>
          <div class="text-cont">
            <h6>iPhone Development</h6>
            <p>
              Getting your product on Apple App store with the latest Updates.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont">
            <i class="icofont icofont-video-clapper"></i>
          </div>
          <div class="text-cont">
            <h6>Online training</h6>
            <p>
              Need a mentor, code reviews or training for your developers we can do that.
            </p>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-video-clapper"></i></div>
          <div class="text-cont">
            <h6>Consulting</h6>
            <p>Its vital to the web development community that all inspiring web developers or your team know how to
              proper problem solve and use the latest tools</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</section>
<section class="section-padding-about">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-lg-offset-right-6">
        <div class="text-left">
          <h2>Why Our Company</h2>
          <p>
            We deliver value and comfort since day #1. All of our guarantees and
            promises are personally from the owners. We promise that our
            services are “Guaranteed 24/7!” and everyone in the company upholds
            that promise as well. It’s a promise you’ll see on everything we
            touch.
          </p>
        </div>
      </div>
    </div>
    <!--div class="row">
    	<div class="col-lg-6 col-lg-offset-right-6">
        	<div class="row">
    			<div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                	<div class="counter-cont">
                    	<h4><span class="numscroller" data-min="1" data-max="5" data-delay="5" data-increment="10">5</span>+</h4>
                        <h6>Clients</h6>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                	<div class="counter-cont">
                    	<h4><span class="numscroller" data-min="1" data-max="5" data-delay="5" data-increment="10">5</span>+</h4>
                        <h6>Projects</h6>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                	<div class="counter-cont">
                    	<h4><span class="numscroller" data-min="1" data-max="2" data-delay="5" data-increment="10">2</span>+</h4>
                        <h6>Products</h6>
                    </div>
                </div>
            </div>
        </div>
    </div-->
  </div>
</section>

<!-- <section class="section-padding-ash">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="section-title text-center">
          <h2>Our Team</h2>
          <h6>Highly motivated indivduals with the a desire for innovation</h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="team-cont"> <img src="assets/images/me_imag.jpg" class="img-fluid" alt="item 1" width="100%">
          <div class="text-cont">
            <h5>Tony Gamble</h5>
            <h6>Founder & Senior Web Developer</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="team-cont"> <img src="assets/images/ik_image.jpg" class="img-fluid" alt="item 1">
          <div class="text-cont">
            <h5>lkhlak Shaikh</h5>
            <h6>Senior Contract Consultant</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="team-cont"> <img src="assets/images/jim_image.jpg" class="img-fluid" alt="item 1">
          <div class="text-cont">
            <h5>Jimmy Auldridge</h5>
            <h6>Junior Contract Consultant</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="section-padding-ash" id="schedule-meeting">
  <div class="container">
    <div class="row">
      <div class="col-xs-12" style="width: 100%">
        <div class="get-in-touch">
          <div class="get-in-touch-container">
            <h2>Get in touch Today</h2>
            <p>
              Looking for a team of flexible developers? Schedule a meeting with
              Goals and Gambles today!
            </p>
            <div class="get-in-touch-btn">
              <a href="https://calendly.com/goalsandgambles/30min" target="_blank">Schedule Meeting</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-padding-ash">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="section-title text-center">
          <h2>Our Recent News</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="blog-post-cont">
          <img src="assets/images/red_bird.jpg" class="img-fluid" alt="item 1" />
          <div class="text-cont">
            <h6>
              <a href="#">Dallas Texas</a> | <a href="#">July 3rd, 2020</a>
            </h6>
            <h5><a href="#">G&G's Offical Launch </a></h5>
            <p>
              We are now offically located at The Dec at Red Bird mall at
              <a href="https://goo.gl/maps/bnex9H65DRT4oTgT8" target="_blank">3662 W Camp Wisdom Rd #2044, Dallas.</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
